<template>
    <b-container fluid>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            <h4><i class="fa fa-briefcase"></i>Berechtigungsgruppen</h4>
                            <p>Liste aller Berechtigungsgruppen</p>
                        </div>

                        <div class="card-tools">
                            <button type="button" class="btn btn-default mr-1" @click="loadPermissionGroups">
                                <i class="fas fa-sync"></i>
                            </button>
                            <button type="button" class="btn btn-primary" @click="createModal" v-if="$auth.check('permission_groups.create')">
                                <i class="fas fa-plus-circle"></i> Neue Berechtigungsgruppe
                            </button>
                            <!-- <a href="#" class="btn btn-primary pull-right">Create User</a> -->
                        </div>
                    </div>

                    <div class="card-body">
                        <b-row class="mb-3">
                            <b-col md="3">
                                <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Type to Search"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-table striped hover outlined :fields="fields" :items="permissionGroups" :filter="filter" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                                    <template #cell(actions)="row">
                                        <b-button size="sm" @click="editModal(row.item)" class="mr-1" variant="warning" v-if="$auth.check('permission_groups.edit')"><i class="fas fa-edit"></i></b-button>
                                        <b-button size="sm" @click="deletePermissionGroup(row.item.id)" variant="danger" v-if="$auth.check('permission_groups.destroy')"><i class="fas fa-trash"></i></b-button>
                                    </template>
                                </b-table>
                                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"></b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </div>
        </div>

        <b-modal id="permissionGroupModal" title="Neue Berechtigungsgruppe erstellen" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk($event, form.id)">
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <div class="row">

                    <div class="col-md-6">

                        <div class="form-group row">
                            <label class="control-label col-sm-2 col-form-label col-form-label-sm">Name</label>
                            <div class="col-sm-9">
                                <input v-model="form.name" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('name')}" tabindex="1"/>
                                <has-error :form="form" field="name"></has-error>
                            </div>
                        </div>

                    </div>

                    <div class="col-md-6">

                        <div class="form-group row">
                            <label class="control-label col-sm-2 col-form-label col-form-label-sm offset-sm-1">Guard</label>
                            <div class="col-sm-9">
                                <select v-model="form.guard_name" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('guard_name')}" tabindex="2" @change="guardChange($event, form.id)">
                                    <option value="api">Api</option>
                                    <option value="web">Web</option>
                                    <option value="customer">Customer</option>
                                </select>
                                <has-error :form="form" field="guard_name"></has-error>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-label col-form-label-sm">Verfügbare Berechtigungen</label>
                            <div class="form-check" v-for="permission in notAssigned" :key="permission.id" >
                                <input type="checkbox" class="form-check-input" :value="permission.id" v-model="form.permissions">
                                <label class="form-check-label">{{permission.name}}</label>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-sm-5 offset-sm-1">
                        <div class="form-group">
                            <label class="form-label col-form-label-sm">Zugewiesene Berechtigungen</label>
                            <div class="form-check" v-for="assignedPermission in form.permissions" :key="assignedPermission.id" >
                                <input type="checkbox" class="form-check-input" v-model="form.permissions" :value="assignedPermission.id"> 
                                <label class="form-check-label">{{assignedPermission}}</label>
                            </div>
                        </div>
                    </div> -->
                </div>
            </form>
        </b-modal>

    </b-container>
</template>

<script>

export default {
    name: 'PermissionGroups',
    title: 'Berechtigungsgruppen',

    data() {
        return {
            form: new window.Form({
                id: "",
                name: "",
                guard_name: "",
                permissions: [],
            }),
            notAssigned: [],
            permissionGroups: [],
            sortBy: "id",
            sortDesc: false,
            perPage: 20,
            currentPage: 1,
            filter: "",
            fields: [
                {key: "id", sortable: true},
                {key: "name", label: "Name", sortable: true},
                {key: "guard_name", label: "Guard", sortable: true},
                {key: "actions", label: "Actions"},
            ],
            editMode: false,
            modalTitle: '',
        }
    },

    methods: {

        guardChange(event, id){
            this.notAssigned = [];
            this.form.permissions = [];
            if(this.editMode == true)
            {
                this.loadNotAssignedPermissionsAndAssigned(event.target.value, id);
            }
            else
            {
                this.loadNotAssignedPermissions(event.target.value);
            }
            
        },

        handleOk(bvModalEvt, id) {
            bvModalEvt.preventDefault()
            this.handleSubmit(id)
        },

        handleSubmit(id) {
            if(this.editMode == true)
            {
                //Edit User
                this.editPermissionGroup(id);
            }
            else
            {
                //Create User
                this.createPermissionGroup();
            }
        },

        createModal() {
            this.editMode = false;
            //this.modalTitle = "Neue Berechtigungsgruppe anlegen";
            this.notAssigned = [];
            this.form.clear();
            this.form.reset();
            this.$bvModal.show("permissionGroupModal");
        },

        editModal(PermissionGroup) {
            this.editMode = true;
            this.loadNotAssignedPermissionsAndAssigned(PermissionGroup.guard_name, PermissionGroup.id);
            this.modalTitle = "Berechtigungsgruppe editieren";
            this.form.reset();
            this.form.fill(PermissionGroup);
            this.$bvModal.show("permissionGroupModal");

        },

        createPermissionGroup() {
            this.$Progress.start();
            this.form
                .post("/permission-groups")
                .then(() => {
                    this.$bvModal.hide("permissionGroupModal");
                    this.$swal({
                        icon: "success",
                        title: "Gruppe wurde erstellt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.loadPermissionGroups();
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        editPermissionGroup(id){
            this.$Progress.start();
            this.form
                .put("/permission-groups/"+ id)
                .then(() => {
                    this.$bvModal.hide("permissionGroupModal");
                    this.$swal({
                        icon: "success",
                        title: "Berechtigungsgruppe wurde editiert",
                    });
                    this.$Progress.finish();
                    this.loadPermissionGroups();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        deletePermissionGroup(id){
            this.$swal({
                title: "Möchtest du die Gruppe wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.form
                        .delete("/permission-groups/" + id)
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Berechtigungsgruppe erfolgreich gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.loadPermissionGroups();
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                        });
                }
            });
        },

        async loadPermissionGroups(){
            this.$Progress.start();
            await this.axios
                .get('/permission-groups')
                .then((response) => {
                    this.permissionGroups = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadNotAssignedPermissions(guard){
            return this.axios
                .get('/permissions/not-assigned/' + guard)
                .then((response) => {
                    this.notAssigned = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },


        loadNotAssignedPermissionsAndAssigned(guard, id){
            return this.axios
                .get('/permissions/not-assigned/' + id + '/' + guard)
                .then((response) => {
                    this.notAssigned = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },
    },

    created() {
        this.loadPermissionGroups();
    },

    computed: {
        tableData() {
            return this.permissionGroups || [];
        },

        rows() {
            return this.permissionGroups.length;
        },
    },

}
</script>

<style>

</style>